<template>
    <lassoLayout>
        <div class="page-logged-out full_page">
            <div class="content-page">
                <div class="block_logged_out">
                    <div class="spaceship"></div>
                    <div class="description">
                        You’ve successfully logged out!
                    </div>
                    <div class="action_buttons">
                        <router-link :to="{name: 'explore'}" class="right primary_btn small" v-ripple>Home</router-link>
                        <router-link :to="{name: 'login'}" class="right tetriatary_btn small" v-ripple>Log In</router-link>
                    </div>
                </div>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
import {mapGetters} from "vuex";

export default {
    name: 'logged-out',
    components: {
        lassoLayout
    },
    data() {
        return {}
    },
    computed: {
	    ...mapGetters({
		    authData: 'AUTH_DATA',
	    }),
    },
    mounted() {
	    if (this.authData.accessToken) {
		    this.$router.push({name: 'explore'});
	    }
    },
    methods: {}
}
</script>
